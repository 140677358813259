import { BrowserRouter } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import SWAbout from "./components/About/SWAbout.jsx";
import Hero from "./components/Hero/Hero.jsx";
import Navbar from "./components/Navbar/Navbar.jsx";
import SWTech from "./components/Tech/SWTech.jsx";
import SWExperience from "./components/Experience/SWExperience.jsx";
import SWWorks from "./components/Works/SWWorks.jsx";
import SWContact from "./components/Contact/SWContact.jsx";
import StarsCanvas from "./components/canvas/Stars.jsx";
import Footer from "./components/Footer/Footer.jsx";

function App() {
  return (
    <BrowserRouter>
      <div className="relative z-0">
        <div className="bg-hero-pattern bg-cover bg-no-repeat bg-center">
          <Navbar />
          <Hero />
        </div>
        <div className="gradiente">
          <SWAbout />
          <SWTech /> 
          <SWWorks />
          <SWExperience />
          {/* <Feedbacks /> */}
          <div className="relative z-0">
            <SWContact />
            <LazyLoad>
              <StarsCanvas />
            </LazyLoad>
          </div>
          <div>
            <Footer />
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
