import { motion } from "framer-motion";
// import React from "react";
// import { styles } from "../../styles";
import { ComputersCanvas } from "../canvas";
import SectionHero from "./SectionHero";

const Hero = () => {
  return (
    <section className="relative w-full h-screen mx-auto">
      <SectionHero />
      <ComputersCanvas />

      <div className="absolute bottom-12 w-full flex justify-center items-center mt-50">
        <a href="#about">
          <div className="w-[35px] h-[64px] rounded-3xl border-4 border-secundary flex justify-center items-start p-2 ">
            <motion.div
              animate={{
                y: [0, 24, 0],
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                repeatType: "loop",
              }}
              className="w-3 h-3 rounded-full bg-secondary mb-1"
            />
          </div>
        </a>
      </div>
    </section>
  );
};

export default Hero;
