// import React from "react";
import { styles } from "../../styles";

const SectionHero = () => {
  return (
    <div
      className={`${styles.paddingX} absolute inset-0 top-[120px] max-w-7xl mx-auto flex flex-row items-start gap-5`}
    >
      <div className="flex flex-col justify-center items-center mt-5">
        <div className="w-5 h-5 rounded-full bg-[#2563eb]" />
        <div className="w-1 sm:h-80 h-40 blue-gradient" />
      </div>

      <div>
        <h1 className={`${styles.heroHeadText}`}>
          Hola, soy <span className="text-[#4f46e5]">Santi</span>
        </h1>
        <p className={`${styles.heroSubText} mt-2`}>
          Soy un developer FullStack.
        </p>
      </div>
    </div>
  );
};

export default SectionHero;
