import qaracter from "../assets/company/qaracter.png";
import unlp from "../assets/company/unlp.png";
import coderhouse from "../assets/company/coderhouse.png";
import bootstrap from "../assets/tech/bootstrap.webp";
import css from "../assets/tech/css.webp";
import sass from "../assets/tech/sass.png";
import git from "../assets/tech/git.webp";
import html from "../assets/tech/html.png";
import javascript from "../assets/tech/javascript.png";
import mongodb from "../assets/tech/mongo-db.png"
import nodejs from "../assets/tech/nodejs.png";
import sql from "../assets/tech/sql.png";
import plsql from "../assets/tech/plsql.png";
import oracle from "../assets/tech/oracle.png";
import reactjs from "../assets/tech/reactjs.png";
import c from "../assets/tech/c.png";
import express from "../assets/tech/expressjs.webp";
import arrow from "../assets/arrow.png";
import cvicon from "../assets/cvicon.png";
import developer from "../assets/developer.png";
import eye from "../assets/eye.png";
import github from "../assets/github.webp";
import linkedin from "../assets/linkedin.png";
import flyshop from "../assets/flyshop.png";
import backend from "../assets/backend-eccomerce.png";
import emecontainers from "../assets/eme-containers.png";
import portfolio from "../assets/portfolio.webp";
import huapi from "../assets/huapi.png";

export const navLinks = [
  {
    id: "about",
    title: "Acerca de",
  },
  {
    id: "work",
    title: "Proyectos",
  },
  {
    id: "contact",
    title: "Contacto",
  },
];

const services = [
  {
    title: "FullStack Developer",
    icon: [developer, "", eye],
  },
  {
    title: "Mi perfil de LinkedIn",
    icon: [linkedin, arrow, eye],
  },
  {
    title: "Mi perfil de Github",
    icon: [github, arrow, eye],
  },
  {
    title: "Mi Curriculum Vitae",
    icon: [cvicon, "", eye],
  },
];

const technologies = [
  {
    name: "JavaScript",
    icon: javascript,
  },
  {
    name: "HTML 5",
    icon: html,
  },
  {
    name: "CSS 3",
    icon: css,
  },  
  {
    name: "SASS",
    icon: sass,
  },
  {
    name: "React JS",
    icon: reactjs,
  },
  {
    name: "Bootstrap",
    icon: bootstrap,
  },
  {
    name: "Node JS",
    icon: nodejs,
  },
  {
    name: "MongoDB",
    icon: mongodb,
  },
  {
    name: "ExpressJS",
    icon: express,
  },
  {
    name: "SQL",
    icon: sql,
  },
  {
    name: "PL/SQL",
    icon: plsql,
  },
  {
    name: "ORACLE SQL DEVELOPER",
    icon: oracle,
  },
  {
    name: "C",
    icon: c,
  },
  {
    name: "Git",
    icon: git,
  },
];

const projects = [
  {
    name: "Portfolio",
    description:
      "Explora mi portfolio,responsive e interactivo, de desarrollador web . Descubre mis habildiades, stack tecnológico, proyectos, etc. Sumérgete en mi mundo tecnológico y déjate inspirar.",
    tags: [
      {
        name: "React",
        color: "blue-text-gradient",
      },
      {
        name: "Threejs",
        color: "green-text-gradient",
      },
      {
        name: "Tailwind",
        color: "orange-text-gradient",
      },
      {
        name: "JavaScript",
        color: "blue-text-gradient",
      },
    ],
    image: portfolio,
    source_code_link: ["https://github.com/santimolina43/portfolio_SantiagoBlasMolina"],
  },
  {
    name: "Huapi",
    description:
      "Aplicación web desarrollada en el marco del curso de Desarrollo Web y Javascript. Consiste un aplicación que presta el servicio de cuidado de mascotas en la cual los usuarios pueden ser dueños o cuidadores. Duración: 4 semanas.",
    tags: [
      {
        name: "HTML",
        color: "blue-text-gradient",
      },
      {
        name: "CSS",
        color: "green-text-gradient",
      },
      {
        name: "SASS",
        color: "orange-text-gradient",
      },
      {
        name: "Javascript",
        color: "blue-text-gradient",
      },
      {
        name: "Bootstrap",
        color: "green-text-gradient",
      },
    ],
    image: huapi,
    source_code_link: [
      "https://github.com/santimolina43/huapi",
      "https://www.huappi.com.ar/",
    ],
  },
  {
    name: "Flyshop",
    description:
      "Proyecto personal de un ecommerce de articulos de pesca. Permite al usuario regiatrarse, iniciar sesion, explorar, seleccionar y adquirir generando ordenes de compra en un entorno amigable. Duración: 4 semanas.",
    tags: [
      {
        name: "Javascript",
        color: "blue-text-gradient",
      },
      {
        name: "React",
        color: "green-text-gradient",
      },
      {
        name: "NodeJS",
        color: "orange-text-gradient",
      },
      {
        name: "CCS",
        color: "blue-text-gradient",
      },
      {
        name: "SASS",
        color: "green-text-gradient",
      },
      {
        name: "Bootstrap",
        color: "orange-text-gradient",
      },
    ],
    image: flyshop,
    source_code_link: [
      "https://github.com/santimolina43/proyecto-flyshop",
      "https://www.huapi-flyshop.com.ar",
    ],
  },
  {
    name: "Ecommerce",
    description:
      "Proyecto personal de un ecommerce (backend y frontend). Permite al usuario administrar productos segun su rol, chatear, registrarse, iniciar sesion y realizar una compra. Duración: 12 semanas.",
    tags: [
      {
        name: "Javascript",
        color: "blue-text-gradient",
      },
      {
        name: "NodeJS",
        color: "green-text-gradient",
      },
      {
        name: "ExpressJS",
        color: "orange-text-gradient",
      },
      {
        name: "MongoDB",
        color: "blue-text-gradient",
      },
      {
        name: "Passport",
        color: "green-text-gradient",
      },
      {
        name: "NEST JS",
        color: "orange-text-gradient",
      },
    ],
    image: backend,
    source_code_link: [
      "https://github.com/santimolina43/santiago-blas-molina-entrega-final-backend",
      "https://web-production-59fe.up.railway.app/",
    ],
  },
  {
    name: "EmeContainers",
    description:
      "Proyecto de una web corporativa de venta de containers para un cliente externo. Permite una navegación fluida y el envio de correos a traves del formulario de contacto. Duración: 3 semanas.",
    tags: [
      {
        name: "HTML",
        color: "blue-text-gradient",
      },
      {
        name: "CSS",
        color: "green-text-gradient",
      },
      {
        name: "SASS",
        color: "orange-text-gradient",
      },
      {
        name: "Javascript",
        color: "blue-text-gradient",
      },
      {
        name: "Bootstrap",
        color: "green-text-gradient",
      }
    ],
    image: emecontainers,
    source_code_link: [
      "https://github.com/santimolina43/EMEContainers",
      "https://eme-containers.com.ar/",
    ],
  },
];

const experiences = [
  {
    title: "FullStack Developer",
    company_name: "CoderHouse",
    icon: coderhouse,
    iconBg: "#383E56",
    date: "Junio 2022 - Actualidad",
    points: [
      "Curso intensivo teórico/práctico de programación JavaScript FrontEnd Developer.",
      "Full-time, con duración de 52 semanas y mas de 800 horas de cursada.",
      "Sólidos conocimientos en HTML, CCS, SASS, JavaScript, React, Git, NodeJs y Express.",
      "Proyectos individuales, grupales y prácticas profesionales con empresas reales.",
    ],
  },
  {
    title: "PL/SQL Developer",
    company_name: "Qaracter - Beyond your challenge",
    icon: qaracter,
    iconBg: "#383E56",
    date: "Septiembre 2021 - Actualidad",
    points: [
      "Desarrollo e implementación de proyectos, utilizando el lenguaje PL/SQL como herramienta para satisfacer las demandas del usuario.",
      "Análisis y desarrollo de requerimientos funcionales/técnicos relacionados con proyectos regulatorios para Santander Global Tech.",
      "Desarrollos en PL/SQL con SQL Developer.",
      "Unix Bash Scripting.",
      "Online/Batch Processing.",
      "Realización de pruebas, tanto integradas como de regresión y sistema en todo el aplicativo del proyecto.",
      "Participación en UATs conectando directamente con el usuario.",
      "Soporte a la producción en las fases de post-implantación.",
      "Gestión de despliegues.",
    ],
  },
  {
    title: "Carrera de Grado",
    company_name: "Universidad Nacional de La Plata",
    icon: unlp,
    iconBg: "#02535e",
    date: "Marzo 2016 - Marzo 2021",
    points: [
      "Ingeniería Industrial", 
      "Promedio 8",
      "Programacion en C",
      "Formulación y Evaluación de Proyectos"
    ],
  },
];

const testimonials = [
  {
    testimonial:
      "Compañero desde el primer momento del Coding Bootcamp y puedo dar fe de su excelente nivel académico, profesional y humano. Siempre colaborativo, aportando ideas y conocimientos, creando un ambiente de trabajo agradable, divertido y productivo.",
    name: "Martín Machado",
    designation: "FullStack Developer",
    company: "Plataforma 5",
    image:
      "https://media.licdn.com/dms/image/C4D03AQHXHtaYD0eLKg/profile-displayphoto-shrink_100_100/0/1651522725096?e=1690416000&v=beta&t=Lz1qQqrsB5tieEv3YgMXfIDIndam-FAVxrwrxbU5Efg",
    link: "https://www.linkedin.com/in/martinmachadodev/",
  },
  {
    testimonial:
      "Trabajé con él en varios proyectos y desde el primer momento demostró su gran capacidad a la hora de resolver problemas, además de su amabilidad y compromiso con el resto del equipo.",
    name: "Mateo Catalano",
    designation: "FullStack Developer",
    company: "Plataforma 5",
    image:
      "https://media.licdn.com/dms/image/D4D03AQHYWIqaZl18cA/profile-displayphoto-shrink_100_100/0/1682000675771?e=1690416000&v=beta&t=5vBRFJ1WrzUy1U0w8ljwgAeRAJJWIduUWJ6bJD9Pi_A",
    link: "https://www.linkedin.com/in/mateo-catalano/",
  },
];

const alerts = [
  "La curiosidad mató al gato.",
  "Nací en Buenos Aires, Argentina.",
  "Si apretas otra vez tendrás otro dato.",
  "La programación es una gran pasión que tengo.",
  "Un gran plan es sentarse con una gran taza de café y programar.",
  "Soy un gran fánatico del fútbol.",
  "Tengo preferencia por el Backend, pero también me divierto en el Frontend.",
  "La risa es contagiosa, incluso si no sabes por qué te ríes.",
  "En el comienzo de JavaScrip no existía la igualdad estricta 😮.",
  "El primer progamador de la historia es mujer y se llamaba: Ada Lovelace.",
  "El primer 'bug' fue en realidad una polilla de verdad que estaba en la computadora.",
  "En mi pasión futbolera, soy hincha de Boca Juniors.",
  "Si estás interesado en mi perfil, no dudes en contactarme 😉.",
];

export { services, technologies, experiences, testimonials, projects, alerts };
