import { motion } from "framer-motion";
import { VerticalTimeline } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import ExperienceCard from "../../commons/ExperienceCard";
import { experiences } from "../../constants";
import { styles } from "../../styles";
import { textVariant } from "../../utils/motion";

export const Experience = () => {
  return (
    <>
      <motion.div variants={textVariant()}>
        <p className={styles.sectionSubText}> Mis estudios </p>
        <h2 className={styles.sectionHeadText}> Educación y Experiencia </h2>
      </motion.div>

      <div className="mt-20 flex flex-col">
        <VerticalTimeline>
          {experiences.map((experience, i) => (
            <ExperienceCard key={i} experience={experience}/>
          ))}
        </VerticalTimeline>
      </div>
    </>
  );
};

